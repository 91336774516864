
import './App.css';
import { Box } from '@chakra-ui/react';
import MainWrapper from './mainWrapper';

function App() {
  return (
    <Box w={'full'} h={'full'}>
      <MainWrapper />
    </Box>

  );
}

export default App;
