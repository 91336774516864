import { Box, Heading } from "@chakra-ui/react";
import React from "react";

const CompanyIntro: React.FC = () => {
    return (
        <Box>
            <Heading as='h1' size='4xl'>
                Librum Technologies Inc <span className="loader__dot">.</span>
            </Heading>
        </Box>
    )
}

export default CompanyIntro;