import React from "react";
import styled from "@emotion/styled";
import Slide from "./Slide";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

interface SlideData {
    key: any;
    content: React.ReactNode;
}

interface VerticalCarouselProps {
    slides: SlideData[];
    goToSlide?: number;
    showNavigation?: boolean;
    offsetRadius?: number;
    animationConfig?: {
        tension: number;
        friction: number;
    };
}

interface VerticalCarouselState {
    index: number;
    goToSlide: number | null;
    prevPropsGoToSlide: number;
    newSlide: boolean;
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NavigationButtons = styled.div`
  position: relative;
  display: flex;

  height: 60px;
  margin: 0 auto;
  width: 20%;
  margin-top: 1rem;
  justify-content: space-between;
  z-index: 1000;
`;

const NavBtn = styled.div`
  background: white;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 3px;
  cursor: pointer;
`;

function mod(a: number, b: number): number {
    return ((a % b) + b) % b;
}

class VerticalCarousel extends React.Component<
    VerticalCarouselProps,
    VerticalCarouselState
> {
    state: VerticalCarouselState = {
        index: 0,
        goToSlide: null,
        prevPropsGoToSlide: 0,
        newSlide: false
    };

    static defaultProps = {
        offsetRadius: 2,
        animationConfig: { tension: 120, friction: 14 }
    };

    componentDidMount = () => {
        document.addEventListener("keydown", (event: KeyboardEvent) => {
            if (event.isComposing || event.keyCode === 229) {
                return;
            }
            if (event.keyCode === 38) {
                this.moveSlide(-1);
            }
            if (event.keyCode === 40) {
                this.moveSlide(1);
            }
        });
    };

    modBySlidesLength = (index: number): number => {
        return mod(index, this.props.slides.length);
    };

    moveSlide = (direction: number) => {
        this.setState({
            index: this.modBySlidesLength(this.state.index + direction),
            goToSlide: null
        });
    };

    clampOffsetRadius(offsetRadius: number) {
        const { slides } = this.props;
        const upperBound = Math.floor((slides.length - 1) / 2);

        if (offsetRadius < 0) {
            return 0;
        }
        if (offsetRadius > upperBound) {
            return upperBound;
        }

        return offsetRadius;
    }

    getPresentableSlides() {
        const { slides } = this.props;
        const { index } = this.state;
        let { offsetRadius } = this.props;
        offsetRadius = this.clampOffsetRadius(offsetRadius!);
        const presentableSlides: SlideData[] = [];

        for (let i = -offsetRadius; i < 1 + offsetRadius; i++) {
            presentableSlides.push(slides[this.modBySlidesLength(index + i)]);
        }

        return presentableSlides;
    }

    render() {
        const { animationConfig, offsetRadius, showNavigation } = this.props;

        let navigationButtons = null;
        if (showNavigation) {
            navigationButtons = (
                <NavigationButtons>
                    <NavBtn onClick={() => this.moveSlide(-1)}>
                        {this.state.index === 0 && 'Products'}
                        {this.state.index === 1 && 'Home'}
                        {this.state.index === 2 && 'Mission'}
                        <ChevronUpIcon />
                    </NavBtn>
                    <NavBtn onClick={() => this.moveSlide(1)}>
                        {this.state.index === 0 && 'Mission'}
                        {this.state.index === 1 && 'Products'}
                        {this.state.index === 2 && 'Home'}
                        <ChevronDownIcon />
                    </NavBtn>

                </NavigationButtons>
            );
        }

        return (
            <React.Fragment>
                <Wrapper>
                    {this.getPresentableSlides().map((slide, presentableIndex) => (
                        <Slide
                            key={slide.key}
                            content={slide.content}
                            delta={[0, 0]}
                            down={true}
                            up={false}
                            showCursor={presentableIndex === this.state.index}
                            moveSlide={this.moveSlide}
                            offsetRadius={this.clampOffsetRadius(offsetRadius!)}
                            index={presentableIndex}
                            animationConfig={animationConfig}
                        />
                    ))}
                </Wrapper>
                {navigationButtons}
            </React.Fragment>
        );
    }
}

export default VerticalCarousel;
