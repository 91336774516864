import { Component } from "react";
import VerticalCarousel from "./VerticalCarousel";
import { config } from "react-spring";
import CompanyIntro from "./CompanyIntro";
import MissonCard from "./MissionCard";
import ProductsCard from "./ProductsCard";


// Define component state interface
interface ExampleState {
  goToSlide: number;
  offsetRadius: number;
  showNavigation: boolean;
  config: any; // react-spring config type is a bit broad, so we keep it as any for flexibility
}

// Slide data array
const slides = [
    { key: 1, content: <CompanyIntro /> },
    { key: 2, content: <MissonCard /> },
    { key: 3, content: <ProductsCard /> }
];

export default class Example extends Component<{}, ExampleState> {
  state: ExampleState = {
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.gentle,
  };

  render() {
    return (
      <div
        style={{
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100vw",
          height: "100vh",
          margin: "0 auto",
        }}
      >
        <VerticalCarousel
          slides={slides}
          offsetRadius={this.state.offsetRadius}
          showNavigation={this.state.showNavigation}
          animationConfig={this.state.config}
        />
      </div>
    );
  }
}
