import { Box, Heading, ListItem, UnorderedList, Text, Link } from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import React from "react";

const MissonCard: React.FC = () => {
    return (
        <Box p={5} maxH={'70vh'} overflow={'auto'}>
            <Heading as='h3' size='lg' mb={12}>
                Librum Technologies, Inc
            </Heading>
            <Text fontSize="lg" mb={12}>
                Librum Technologies is committed to developing cutting-edge technologies that harness the power of innovative algorithms and AI models to revolutionize how people learn new knowledge from written materials, enhancing learning and research experiences at all levels from post-primary to undergraduate education, and from graduate studies to post-doctoral research. Our mission is to provide essential tools that empower students and researchers across all disciplines to achieve their academic and professional goals.
            </Text>
            <Text fontSize="lg" mb={4}>
                Our major product lines include, but are not limited to, integrated platforms for:
            </Text>
            <UnorderedList mb={12} fontSize="lg">
                <ListItem>
                    <strong>Learning Experience Enhancement.</strong> The product empowers users to become more effective learners by offering interpretive analysis of texts, tables, math expressions, and figures, as well as personalized, Socratic-style inspirations that foster deeper understanding of research papers and teaching materials. This is facilitated through a dynamic question-and-answer format, where users' queries are addressed with system-generated responses on any topics covered in the research papers or materials provided by the user. Additionally, it creates a central hub for the user to organize reading materials, user-written notes, study sessions of user queries and system responses to meet individual learning needs.
                </ListItem>
                <ListItem>
                    <strong>Academic Paper Editing.</strong> The product assists users in crafting well-structured research papers that clearly and effectively communicate their findings, tailored to specific requirements and writing styles.
                </ListItem>
                <ListItem>
                    <strong>Academic Paper Assessing.</strong> The product automatically assesses the merits of research papers, whether self-authored or written by others, supporting automatic evaluation for papers submitted to professional venues.
                </ListItem>
                <ListItem>
                    <strong>Semantic Search.</strong> The product offers advanced search capabilities by understanding the meanings behind user-specified topics, providing more relevant results than traditional keyword and phrase searches.
                </ListItem>
                <ListItem>
                    <strong>Comprehensive Literature Reviewing.</strong> The product generates in-depth literature surveys on user-specified topics, based on semantic search and user-provided papers, providing a thorough overview of relevant research.
                </ListItem>
            </UnorderedList>
            <Heading as='h5' size='sm'>
                Released Products and Technology Showcases:
            </Heading>

            <UnorderedList mb={4} fontSize="lg">
                <ListItem>
                    <Link href='https://doenba.ai/' isExternal>
                        Doenba <ExternalLinkIcon mx='2px' />
                    </Link>
                </ListItem>
                <ListItem>
                    <Link href='https://dooyeed.ai/' isExternal>
                        Technology showcases for pre-college and college educations <ExternalLinkIcon mx='2px' />
                    </Link>
                </ListItem>
                <ListItem>
                    <Link href='https://www.youtube.com/watch?v=w-3Q6tIyCMY&t=3s' isExternal>
                        Librum AI transforms Teaching and Learning <ExternalLinkIcon mx='2px' />
                    </Link></ListItem>
            </UnorderedList>
        </Box>
    )
}

export default MissonCard;