import { Center, Flex, Heading } from "@chakra-ui/react";
import React from "react";

const ProductsCard: React.FC = () => {

    const navigateTo = (url: string) => () => {
        window.open(url, '_blank');
    };
        
    return (
        <Flex flexDir={'column'}>
            <Heading as='h3' size='lg' mb={12} w={'full'} textAlign={'center'}>
                Released Products and Technology Showcases
            </Heading>
            <Flex flexDir={'row'}>
                <Center onClick={navigateTo('https://doenba.ai/')} fontSize={25} _hover={{ bg: '#f9f9f9' }} transition={'all 0.5s ease'} cursor={'pointer'} boxShadow={'md'} w={'300px'} h={'300px'} mr={5} borderRadius={12}>
                    Doenba
                </Center>
                <Center onClick={navigateTo('https://dooyeed.ai/')}fontSize={25} _hover={{ bg: '#f9f9f9' }} transition={'all 0.5s ease'} cursor={'pointer'} boxShadow={'md'} w={'300px'} h={'300px'} mr={5} borderRadius={12}>
                    Dooyeed
                </Center>
                <Center onClick={navigateTo('https://www.youtube.com/watch?v=w-3Q6tIyCMY&t=3s')} fontSize={25} _hover={{ bg: '#f9f9f9' }} transition={'all 0.5s ease'} cursor={'pointer'} boxShadow={'md'} w={'300px'} h={'300px'} borderRadius={12}>
                    Librum
                </Center>
            </Flex>
        </Flex>
    )
}

export default ProductsCard;